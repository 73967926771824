import { GoodsProdctCodeEnum, PrintingPartnerEnum } from "interface/goods/Code"

export const TEMP_VERIFY_ORDER_PRODUCTS = [
  {
    eventType: 'ZERO_PRODUCT',
    productNo: 122401724,
  },
  {
    eventType: 'ZERO_PRODUCT',
    productNo: 122666609,
  },
  {
    eventType: 'ZERO_PRODUCT',
    productNo: 122666937,
  },
  {
    eventType: 'ZERO_PRODUCT',
    productNo: 122666897,
  },
  // {
  //   eventType: 'ZERO_PRODUCT',
  //   productNo: 122372493,
  // },
  // {
  //   eventType: 'ZERO_PRODUCT',
  //   productNo: 120769473,
  // },
]

export interface ITempDisplay {
    id: number
    productNo: number
    eventName: string
    eventType: string
    promotionName: string
    displayType: string
    displayTestStartDate: string
    displayStartDate: string
    displayEndDate: string
    nextDisplayStartDate: string | null
    startActionType: string
    startActionValue: string
    endActionType: string
    endActionValue: string
    errorMessage: string
}
export const TEMP_DISPLAY_LIST: ITempDisplay[] = [
  // {
  //     id: 0,
  //     productNo: 120703365,
  //     eventName: '무료인화',
  //     promotionName: 'FREE_PRINT',
  //     displayType: 'CRON',
  //     displayStartDate: '2024-08-14T00:00:00',
  //     displayEndDate: '2024-08-14T18:00:00',
  //     nextDisplayStartDate: '2024-08-15T00:00:00',
  //     startActionType: 'REDIRECT',
  //     startActionValue: '/shop/promotion?no={:productNo}',
  //     endActionType: 'REDIRECT',
  //     endActionValue: '/shop/promotion?no={:productNo}',
  // },
  {
      id: 1,
      productNo: 120703365,
      eventName: '무료인화',
      promotionName: 'FREE_PRINT',
      eventType: 'FREEPRINT',
      displayType: 'CRON',
      displayTestStartDate: '2024-09-06T00:00:00',
      displayStartDate: '2024-10-11T00:00:00', // '2024-10-11T00:00:00',
      displayEndDate: '2099-10-16T00:00:00',
      nextDisplayStartDate: '2024-10-15T00:00:00',
      startActionType: 'REDIRECT',
      startActionValue: '/shop/promotion?no={:productNo}',
      endActionType: 'REDIRECT',
      endActionValue: '/shop/promotion?no={:productNo}',
      errorMessage: '무료인화 상품 구매기간이 아닙니다.',
  },
  // {
  //     id: 1,
  //     productNo: 120703365,
  //     eventName: '무료인화',
  //     promotionName: 'FREE_PRINT_A',
  //     eventType: 'FREEPRINT_A',
  //     displayType: 'CRON',
  //     displayTestStartDate: '2024-09-06T00:00:00',`
  //     displayStartDate: '2024-10-11T00:00:00', // '2024-10-11T00:00:00',
  //     displayEndDate: '2024-10-16T00:00:00',
  //     nextDisplayStartDate: '2024-10-15T00:00:00',
  //     startActionType: 'REDIRECT',
  //     startActionValue: '/shop/promotion?no={:productNo}',
  //     endActionType: 'REDIRECT',
  //     endActionValue: '/shop/promotion?no={:productNo}',
  //     errorMessage: '무료인화 상품 구매기간이 아닙니다.',
  // },
  // {
  //     id: 1,
  //     productNo: 120703365,
  //     eventName: '무료인화',
  //     promotionName: 'FREE_PRINT_B',
  //     eventType: 'FREE_PRINT_B',
  //     displayType: 'CRON',
  //     displayTestStartDate: '2024-09-06T00:00:00',
  //     displayStartDate: '2024-10-20T00:00:00', // '2024-10-20T00:00:00',
  //     displayEndDate: '2024-10-24T00:00:00',
  //     nextDisplayStartDate: '2024-10-15T00:00:00',
  //     startActionType: 'REDIRECT',
  //     startActionValue: '/shop/promotion?no={:productNo}',
  //     endActionType: 'REDIRECT',
  //     endActionValue: '/shop/promotion?no={:productNo}',
  //     errorMessage: '무료인화 상품 구매기간이 아닙니다.',
  // },
  // {
  //     id: 1,
  //     productNo: 120703365,
  //     eventName: '무료인화',
  //     promotionName: 'FREE_PRINT_C',
  //     eventType: 'FREE_PRINT_C',
  //     displayType: 'CRON',
  //     displayTestStartDate: '2024-09-06T00:00:00',
  //     displayStartDate: '2024-10-20T22:00:00', // 2024-09-19T00:00:00
  //     displayEndDate: '2099-10-31T00:00:00',
  //     nextDisplayStartDate: '2024-10-15T00:00:00',
  //     startActionType: 'REDIRECT',
  //     startActionValue: '/shop/freeprint?no={:productNo}',
  //     endActionType: 'REDIRECT',
  //     endActionValue: '/shop/promotion?no={:productNo}',
  //     errorMessage: '무료인화 상품 구매기간이 아닙니다.',
  // },
  {
      id: 2,
      productNo: 120862275,
      eventName: '무료인화',
      promotionName: 'FREE_PRINT_NEWUSER',
      eventType: 'NEWUSER',
      displayType: 'CRON',
      displayTestStartDate: '2024-08-30T00:00:00',
      displayStartDate: '2024-08-30T00:00:00',
      displayEndDate: '2099-09-04T00:00:00',
      nextDisplayStartDate: null,
      startActionType: 'REDIRECT',
      startActionValue: '/shop/promotion?no={:productNo}',
      endActionType: 'REDIRECT',
      endActionValue: '/shop/promotion?no={:productNo}',
      errorMessage: '무료인화 상품 구매기간이 아닙니다.',
  },
  ...TEMP_VERIFY_ORDER_PRODUCTS.map((product, index) => ({
      id: index + 3,
      productNo: product.productNo,
      eventName: '구독자 전용 0원 상품',
      promotionName: 'ZERO_PRODUCT',
      eventType: product.eventType,
      displayType: 'CRON',
      displayTestStartDate: '2024-08-30T00:00:00',
      displayStartDate: '2024-09-21T10:30:00',
      displayEndDate: '2099-09-29T00:00:00',
      nextDisplayStartDate: null,
      startActionType: 'NONE',
      startActionValue: '',
      endActionType: 'REDIRECT',
      endActionValue: '/shop/products/expired',
      errorMessage: '상품 구매기간이 아닙니다.',
  })),
]

export const PRINT_EXCHANGE = {
    coupons: [
        {
            productNo: 114140583,
            couponNos: [73399],
        },
        {
            productNo: 114140613,
            couponNos: [73401],
        },
        {
            productNo: 114140617,
            couponNos: [73403],
        },
    ],
    applicableProductNos: [
        114162769, // 3x4
        114162823, // 4x6
    ]
}

export const CALENDAR_2024 = {
    productNos: [
        114623725,
        114701837,
        114701833,
        114701831,
        106853893,
        114838177,
        114652491,
        114701827,
    ],
    aosMinVer: 605,
    iosMinVer: 951,
}

export const GOODS_VERSION_CHECK: {
    label: string
    productNos: number[],
    aosMinVer: number | null,
    iosMinVer: number | null
}[] = [
    {
        label: '포토카드',
        productNos: [115375553],
        aosMinVer: 614,
        iosMinVer: 965,
    },
    {
        label: '3x4 인화',
        productNos: [106807551, 114162769, 115436871],
        aosMinVer: 614,
        iosMinVer: 965,
    },
    {
        label: '2024 달력',
        ...CALENDAR_2024
    }
]

export interface ISmstMappingOptionConfig {
    smstOptionNo: number | null
    shopbyOptionNo: number | null
    name: string
}
export interface IMappingProductConfig {
    shopbyProductNo: number | null
    shopbyProductName: string
    smstProductNo: number | null
    goodsCode: GoodsProdctCodeEnum
    printPartner: PrintingPartnerEnum
    altPrintPartner?: PrintingPartnerEnum[]
    options: ISmstMappingOptionConfig[]
}

export const SMST_MAPPING_CALENDAR_2024: IMappingProductConfig[] = [
    {
        shopbyProductNo: 114623725,
        shopbyProductName: '大사이즈 만년 효도 달력',
        smstProductNo: 9466213914,
        // coupang 15182079668
        printPartner: PrintingPartnerEnum.REDPRINTING,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 137965845,
                smstOptionNo: 44829209472,
                // oupang 91207291664
                name: '레트로+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 137965841,
                smstOptionNo: 44829209469,
                // oupang 91207291656
                name: '음력/절기+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 137965837,
                smstOptionNo: 44829209474,
                // oupang 91207291687
                name: '한문+영상+월페이퍼',
            },
        ]
    },
    {
        shopbyProductNo: -1,
        shopbyProductName: '단체용 대자 효도달력',
        smstProductNo: 11075680342,
        printPartner: PrintingPartnerEnum.REDPRINTING,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45191141039,
                name: '레트로+영상+월페이퍼',
            },
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45191141037,
                name: '음력/절기+영상+월페이퍼',
            },
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45191141041,
                name: '한문+영상+월페이퍼',
            },
        ]
    },
    {
        shopbyProductNo: 114652491,
        shopbyProductName: '[무료배송] 심플 탁상(미니/베이직) 달력',
        smstProductNo: 9466273095,
        // coupang 15213210434
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 137762409,
                smstOptionNo: 44829229078,
                // coupang 91315860425
                name: '미니+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 137762411,
                smstOptionNo: 44829229079,
                // coupang 91315860432
                name: '베이직+영상+월페이퍼',
            },
        ]
    },
    {
        shopbyProductNo: -1,
        shopbyProductName: '단체용 탁상달력',
        smstProductNo: 11097996537,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45192356965,
                name: '미니+영상+월페이퍼',
            },
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45192356968,
                name: '베이직+영상+월페이퍼',
            },
        ]
    },
    {
        shopbyProductNo: 114701827,
        shopbyProductName: '[무료배송] 일러스트 탁상 (미니/베이직) 달력',
        smstProductNo: 9466258018,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 135349561,
                smstOptionNo: 36091808695,
                name: '미니',
            },
            {
                shopbyOptionNo: 135349565,
                smstOptionNo: 36091808697,
                name: '베이직',
            },
        ]
    },
    {
        shopbyProductNo: 114701831,
        shopbyProductName: '[무료배송] 우드스탠드 달력 (우드스탠드 포함)',
        smstProductNo: 9466240898,
        printPartner: PrintingPartnerEnum.PHOTOMON,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 137035737,
                smstOptionNo: 9466240898,
                name: '베이직',
            },
        ]
    },
    {
        shopbyProductNo: 114701833,
        shopbyProductName: '[무료배송] 우드 행잉 달력 (우드바포함)',
        smstProductNo: 9466222347,
        printPartner: PrintingPartnerEnum.PHOTOMON,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 137035735,
                smstOptionNo: 9466222347,
                name: '베이직',
            },
        ]
    },
    {
        shopbyProductNo: 114701837,
        shopbyProductName: '[무료배송]中사이즈 효도 달력 (디자인 3종 택1)',
        smstProductNo: 9466218002,
        // coupang 15212528202
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 135349869,
                smstOptionNo: 44829217428,
                // coupang 91313274374
                name: "레트로+영상+월페이퍼",
            },
            {
                shopbyOptionNo: 135350077,
                smstOptionNo: 44829217429,
                // coupang 91313274384
                name: "한문+영상+월페이퍼",
            },
            {
                shopbyOptionNo: 135350081,
                smstOptionNo: 44829217427,
                // coupang 91313274366
                name: "음력/절기+영상+월페이퍼",
            },
        ]
    },
    {
        shopbyProductNo: -1,
        shopbyProductName: '단체용 중자 효도달력',
        smstProductNo: 11097995164,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45192338866,
                name: "레트로+영상+월페이퍼",
            },
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45192338867,
                name: "한문+영상+월페이퍼",
            },
            {
                shopbyOptionNo: -1,
                smstOptionNo: 45192338865,
                name: "음력/절기+영상+월페이퍼",
            },
        ]
    },
    {
        shopbyProductNo: 114838177,
        shopbyProductName: '[무료배송] 심플 벽걸이 달력',
        smstProductNo: 9466152682,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 138002591,
                smstOptionNo: 44829201792,
                // coupang 91315835727
                name: '심플',
            },
        ]
    },
    {
        shopbyProductNo: 123552429,
        shopbyProductName: '스케줄 달력',
        smstProductNo: 11074424941,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
            {
                shopbyOptionNo: 233900959,
                smstOptionNo: 45076823571,
                // coupang 
                name: '컬러풀+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 233900960,
                smstOptionNo: -1,
                // coupang 
                name: '컬러풀(3개묶음/동일디자인할인)+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 233900961,
                smstOptionNo: 45076823573,
                // coupang 
                name: '심플+영상+월페이퍼',
            },
            {
                shopbyOptionNo: 233900962	,
                smstOptionNo: -1,
                // coupang 
                name: '심플(3개묶음/동일디자인할인)+영상+월페이퍼',
            },
        ]
    },
    {
        shopbyProductNo: 123889703,
        shopbyProductName: '듀얼 양면 스케줄 플래너 사무실 일정 강아지 탁상 달력 포토 카렌다 (1+1부, 2+1부) [원쁠딜용]',
        smstProductNo: 11116597514,
        printPartner: PrintingPartnerEnum.STOPBOOK,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
          {
            shopbyOptionNo: 242655433,
            smstOptionNo: 45280997120,
            name: "컬러풀1+컬러풀1(동일사진 총 2부)",
          },
          {
            shopbyOptionNo: 242655435,
            smstOptionNo: 45280997121,
            name: "심플1+심플1(동일사진 총 2부)",
          },
          {
            shopbyOptionNo: 242658484,
            smstOptionNo: 45280997122,
            name: "컬러풀2+컬러풀1(동일사진 총 3부)",
          },
          {
            shopbyOptionNo: 242658485,
            smstOptionNo: 45280997123,
            name: "심플2+심플1(동일사진 총 3부)",
          },
      ],
    },
    {
        shopbyProductNo: 123889462,
        shopbyProductName: '대자 레트로 큰글씨 음력 포토 제작 효도 은행 달력 세트(1+1부,2+1부) [원쁠딜용]',
        smstProductNo: 11107221108,
        printPartner: PrintingPartnerEnum.REDPRINTING,
        goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
        options: [
          {
            shopbyOptionNo: 242649591,
            smstOptionNo: 45239702298,
            name: "음력/절기1+음력절기1 (동일사진 총2부)",
          },
          {
            shopbyOptionNo: 242649593,
            smstOptionNo: 45239702299,
            name: "레트로1+레트로1 (동일사진 총2부)",
          },
          {
            shopbyOptionNo: 242649589,
            smstOptionNo: 45239702300,
            name: "한문요일1+한문요일1(동일사진 총2부)",
          },
          {
            shopbyOptionNo: 242659373,
            smstOptionNo: 45239702302,
            name: "음력/절기2+음력/절기1(동일사진 총3부)",
          },
          {
            shopbyOptionNo: 242659374,
            smstOptionNo: 45239702305,
            name: "레트로2+레트로1 (동일사진 총3부)",
          },
          {
            shopbyOptionNo: 242659372,
            smstOptionNo: 45239702307,
            name: "한문요일2+한문요일1(동일사진 총3부)",
          },
      ],
    },
]

export const GOODS_PRODUCT_MAPPING: IMappingProductConfig[] = [
  ...SMST_MAPPING_CALENDAR_2024,
    {
        shopbyProductNo: 116474715,
        shopbyProductName: '미아방지 키링',
        smstProductNo: 9920703595,
        printPartner: PrintingPartnerEnum.HUSK,
        goodsCode: GoodsProdctCodeEnum.KEYRING,
        options: [
            {
              name: "1.토끼|1개",
              shopbyOptionNo: 151441149,
              smstOptionNo: 38688358695
            },
            {
              name: "1.토끼|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441151,
              smstOptionNo: -1
            },
            {
              name: "토끼",
              shopbyOptionNo: 151441153,
              smstOptionNo: -1
            },
            {
              name: "2.강아지|1개",
              shopbyOptionNo: 151441155,
              smstOptionNo: 38688358696
            },
            {
              name: "2.강아지|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441157,
              smstOptionNo: -1
            },
            {
              name: "강아지",
              shopbyOptionNo: 151441159,
              smstOptionNo: -1
            },
            {
              name: "3.개구리|1개",
              shopbyOptionNo: 151441161,
              smstOptionNo: 38688358697
            },
            {
              name: "3.개구리|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441163,
              smstOptionNo: -1
            },
            {
              name: "개구리",
              shopbyOptionNo: 151441165,
              smstOptionNo: -1
            },
            {
              name: "4.호랑이|1개",
              shopbyOptionNo: 151441167,
              smstOptionNo: 38688358698
            },
            {
              name: "4.호랑이|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441169,
              smstOptionNo: -1
            },
            {
              name: "호랑이",
              shopbyOptionNo: 151441171,
              smstOptionNo: -1
            },
            {
              name: "5.판다|1개",
              shopbyOptionNo: 151441173,
              smstOptionNo: 38688358699
            },
            {
              name: "5.판다|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441175,
              smstOptionNo: -1
            },
            {
              name: "판다",
              shopbyOptionNo: 151441177,
              smstOptionNo: -1
            },
            {
              name: "7.병아리|1개",
              shopbyOptionNo: 151441185,
              smstOptionNo: 38688358701
            },
            {
              name: "7.병아리|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441187,
              smstOptionNo: -1
            },
            {
              name: "병아리",
              shopbyOptionNo: 151441189,
              smstOptionNo: -1
            },
            {
              name: "8.고양이|1개",
              shopbyOptionNo: 151441191,
              smstOptionNo: 38688358702
            },
            {
              name: "8.고양이|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441193,
              smstOptionNo: -1
            },
            {
              name: "고양이",
              shopbyOptionNo: 151441195,
              smstOptionNo: -1
            },
            {
              name: "9.양|1개",
              shopbyOptionNo: 151441197,
              smstOptionNo: 38688358703
            },
            {
              name: "9.양|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441199,
              smstOptionNo: -1
            },
            {
              name: "양",
              shopbyOptionNo: 151441201,
              smstOptionNo: -1
            },
            {
              name: "6.공룡|1개",
              shopbyOptionNo: 151441179,
              smstOptionNo: 38688358700
            },
            {
              name: "6.공룡|2개(동일디자인)-개당7900원",
              shopbyOptionNo: 151441181,
              smstOptionNo: -1
            },
            {
              name: "공룡",
              shopbyOptionNo: 151441183,
              smstOptionNo: -1
            },
            {
              name: "1.토끼|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638931,
              smstOptionNo: -1
            },
            {
              name: "2.강아지|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638933,
              smstOptionNo: -1
            },
            {
              name: "3.개구리|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638935,
              smstOptionNo: -1
            },
            {
              name: "4.호랑이|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638937,
              smstOptionNo: -1
            },
            {
              name: "5.판다|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638939,
              smstOptionNo: -1
            },
            {
              name: "6.공룡|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638941,
              smstOptionNo: -1
            },
            {
              name: "7.병아리|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638943,
              smstOptionNo: -1
            },
            {
              name: "8.고양이|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638945,
              smstOptionNo: -1
            },
            {
              name: "9.양|4개(무배/동일디자인)-개당6700원",
              shopbyOptionNo: 156638947,
              smstOptionNo: -1
            }
          ]
    },
    {
        shopbyProductNo: 116177257,
        shopbyProductName: '효도손',
        smstProductNo: 9882007002,
        printPartner: PrintingPartnerEnum.REDPRINTING,
        goodsCode: GoodsProdctCodeEnum.GSSKHND,
        options: [
            {
                smstOptionNo: 38409668038,
                shopbyOptionNo: 149528571,
                name: "1.강아지_우리강아지 손이 약손",
            },
            {
                smstOptionNo: 38409668039,
                shopbyOptionNo: 149528573,
                name: "2.감_감사합니다 효도할게요",
            },
            {
                smstOptionNo: 38409668040,
                shopbyOptionNo: 149528575,
                name: "3.복_긁는 만큼 복이 잔-뜩",
            },
            {
                smstOptionNo: 38409668041,
                shopbyOptionNo: 149528577,
                name: "4-1.용_할머니 건강하세용",
            },
            {
                smstOptionNo: 38409668042,
                shopbyOptionNo: 149528579,
                name: "4-2.용_할아버지 건강하세용",
            },
            {
                smstOptionNo: 38409668043,
                shopbyOptionNo: 149528581,
                name: "5-1.콩_할머니랑 알콩달콩",
            },
            {
                smstOptionNo: 38409668044,
                shopbyOptionNo: 149528583,
                name: "5-2.콩_할아버지랑 알콩달콩",
            },
            {
                smstOptionNo: 38409668045,
                shopbyOptionNo: 149528585,
                name: "6.토끼_토끼같은내새꾸가긁어주는중",
            },
            {
                smstOptionNo: 38409668046,
                shopbyOptionNo: 149528587,
                name: "7-1.할머니를 사랑할수박에",
            },
            {
                smstOptionNo: 38409668047,
                shopbyOptionNo: 149528589,
                name: "7-2.할아버지를 사랑할수박에",
            },
            {
                smstOptionNo: 38409668048,
                shopbyOptionNo: 149528591,
                name: "8-1.나는야 할머니 바라기",
            },
            {
                smstOptionNo: 38409668049,
                shopbyOptionNo: 149528593,
                name: "8-2.나는야 할아버지 바라기",
            },
            {
                smstOptionNo: 38409668050,
                shopbyOptionNo: 149528595,
                name: "9-1.나는야 할머니의 비타민",
            },
            {
                smstOptionNo: 38409668051,
                shopbyOptionNo: 149528597,
                name: "9-2.나는야 할아버지의 비타민",
            },
            {
                smstOptionNo: 38568519052,
                shopbyOptionNo: 150850665,
                name: "11-1.아빠 건강하세용",
            },
            {
                smstOptionNo: 38568519053,
                shopbyOptionNo: 150850667,
                name: "11-2.엄마 건강하세용",
            },
            {
                smstOptionNo: 38568519054,
                shopbyOptionNo: 150850669,
                name: "12-1.아빠랑 알콩달콩",
            },
            {
                smstOptionNo: 38568519055,
                shopbyOptionNo: 150850671,
                name: "12-2.엄마랑 알콩달콩",
            },
            {
                smstOptionNo: 38568519056,
                shopbyOptionNo: 150850673,
                name: "13-1.아빠를 사랑할 수박에",
            },
            {
                smstOptionNo: 38568519057,
                shopbyOptionNo: 150850675,
                name: "13-2.엄마를 사랑할 수박에",
            },
            {
                smstOptionNo: 38568519058,
                shopbyOptionNo: 150850677,
                name: "14-1.나는야 아빠바라기",
            },
            {
                smstOptionNo: 38568519059,
                shopbyOptionNo: 150850679,
                name: "14-2.나는야 엄마바라기",
            },
            {
                smstOptionNo: 38568519060,
                shopbyOptionNo: 150850681,
                name: "15-1.나는야 아빠의비타민",
            },
            {
                smstOptionNo: 38568519061,
                shopbyOptionNo: 150850739,
                name: "15-2.나는야 엄마의비타민",
            },
        ]
    },
    {
      shopbyProductNo: null,
      smstProductNo: 10164649286,
      shopbyProductName: "아크릴 등신대",
      printPartner: PrintingPartnerEnum.HUSK, // TODO 확인 필요
      goodsCode: GoodsProdctCodeEnum.ACRYLIC,
      options: []
    },
    {
      shopbyProductNo: 118286885,
      smstProductNo: 10164658127,
      shopbyProductName: "머그컵",
      printPartner: PrintingPartnerEnum.HUSK,
      altPrintPartner: [PrintingPartnerEnum.DEKKANG],
      goodsCode: GoodsProdctCodeEnum.ACRYLIC,
      options: [
        {
          shopbyOptionNo: 190685453,
          smstOptionNo: 40414294203,
          name: "머리띠"
        },
        {
          shopbyOptionNo: 190685452,
          smstOptionNo: 40414294201,
          name: "배달"
        },
        {
          shopbyOptionNo: 190685450,
          smstOptionNo: 40414294197,
          name: "원고지"
        },
        {
          shopbyOptionNo: 190685451,
          smstOptionNo: 40414294199,
          name: "편지지"
        }
      ]
    },
    {
      shopbyProductNo: 118285599,
      smstProductNo: 10164729569,
      shopbyProductName: "LED 무드등",
      printPartner: PrintingPartnerEnum.DEKKANG,
      altPrintPartner: [PrintingPartnerEnum.CUSTOMX],
      goodsCode: GoodsProdctCodeEnum.ACRYLIC,
      options: [
        {
          shopbyOptionNo: 178027381,
          smstOptionNo: 40225970898,
          name: "추가안함O"
        },
        {
          shopbyOptionNo: 178027380,
          smstOptionNo: 40225970897,
          name: "추가안함X"
        }
      ]
    },
    {
      shopbyProductNo: 118074681,
      smstProductNo: 10164589875,
      shopbyProductName: "카네이션 효도손",
      printPartner: PrintingPartnerEnum.REDPRINTING,
      goodsCode: GoodsProdctCodeEnum.GSSKHND,
      options: [
        {
          shopbyOptionNo: 190656770,
          smstOptionNo: 40413302579, // 40225510205,
          name: "카네이션 머리띠"
        },
        {
          shopbyOptionNo: 190656771,
          smstOptionNo: 40413302580, // 40225510206,
          name: "카네이션"
        }
      ]
    },
    {
      shopbyProductNo: 118087207,
      smstProductNo: 10164553445,
      shopbyProductName: "구두주걱",
      printPartner: PrintingPartnerEnum.REDPRINTING,
      goodsCode: GoodsProdctCodeEnum.GSSKHND,
      options: [
        {
          shopbyOptionNo: 169249117,
          smstOptionNo: null,
          name: "1-1. 미니_카네이션 머리띠",
        },
        {
          shopbyOptionNo: 169249119,
          smstOptionNo: null,
          name: "1-2. 미니_카네이션",
        },
        {
          shopbyOptionNo: 169249121,
          smstOptionNo: null,
          name: "2-1. 중_카네이션 머리띠",
        },
        {
          shopbyOptionNo: 169249123,
          smstOptionNo: null,
          name: "2-2. 중_카네이션",
        },
        {
          shopbyOptionNo: null,
          smstOptionNo: 40225258635,
          name: "미니",
        },
        {
          shopbyOptionNo: null,
          smstOptionNo: 40225258636,
          name: "중자",
        },
      ]
    },
    {
      shopbyProductNo: 119462416,
      smstProductNo: 10228297923,
      shopbyProductName: "다이어리 폰케이스",
      printPartner: PrintingPartnerEnum.DEKKANG,
      goodsCode: GoodsProdctCodeEnum.ACRYLIC,
      options: [
        {
          shopbyOptionNo: 195158855, // 194382355, // 190520716,
          smstOptionNo: 40760605944, // 40723185958, // 40603048093,
          name: "와인"
        },
        {
          shopbyOptionNo: 195158854, // 194382354, // 190520717,
          smstOptionNo: 40760605943, // 40723185959, // 40603048092,
          name: "네이비"
        }
      ]
    },
    // //임시추가
    // {
    //   shopbyProductNo: 114623725,
    //   shopbyProductName: '[무료배송]大사이즈 효도 달력  (디자인 3종 택1)',
    //   smstProductNo: 9466213914,
    //   printPartner: PrintingPartnerEnum.REDPRINTING,
    //   goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
    //   options: [
    //       {
    //           shopbyOptionNo: 137965845,
    //           smstOptionNo: 36091556620,
    //           name: '레트로',
    //       },
    //       {
    //           shopbyOptionNo: 137965841,
    //           smstOptionNo: 36091556619,
    //           name: '클래식(영문요일)',
    //       },
    //       {
    //           shopbyOptionNo: 137965837,
    //           smstOptionNo: 36091556618,
    //           name: '클래식(한문요일)',
    //       },
    //   ]
    // },
    // {
    //   shopbyProductNo: 114701837,
    //   shopbyProductName: '[무료배송]中사이즈 효도 달력 (디자인 3종 택1)',
    //   smstProductNo: 9466218002,
    //   printPartner: PrintingPartnerEnum.REDPRINTING,
    //   goodsCode: GoodsProdctCodeEnum.CALENDAR_2024,
    //   options: [
    //       {
    //           shopbyOptionNo: 135349869,
    //           smstOptionNo: 36091574068,
    //           name: '레트로',
    //       },
    //       {
    //           shopbyOptionNo: 135350077,
    //           smstOptionNo: 36091574066,
    //           name: '한문 요일',
    //       },
    //       {
    //           shopbyOptionNo: 135350081,
    //           smstOptionNo: 36091574067,
    //           name: '영문 요일',
    //       },
    //   ]
    // }
]

export const WALLA_GOODS_PRODUCT_MAPPING: { [key: number]: string } = {
  121279588: "https://walla.my/survey/lO0pHzSwt1ntTbnpd1lr", // 안전운전 흔들등신대 
  120620960: "https://walla.my/survey/KMarbevOAGCbxdYOEai8", // 다이어리 케이스 
  120620953: "https://walla.my/survey/KMarbevOAGCbxdYOEai8", // 다이어리 케이스 
  119462533: "https://walla.my/survey/KMarbevOAGCbxdYOEai8", // 다이어리 케이스 
  119462416: "https://walla.my/survey/KMarbevOAGCbxdYOEai8", // 다이어리 케이스 
  118572660: "https://walla.my/survey/2j1W9Nj0Ptsx3peZdFXz", // 대두세트(그립톡+쑥티콘케이스)
  110807437: "https://walla.my/survey/7AGuJofKwhhYbOW6VR5q", // 기념일용 아크릴등신대 (대자)
  118401743: "https://walla.my/survey/CTbi33miId9uJoGF5Qja", // 발도장 led
  118723445: "https://walla.my/survey/9v6g2njDhezq2u719q3T", // 아이그림 폰케이스
  118804600: "https://walla.my/survey/o2ph1XBbctsdAuGbPAHF", // 아이그림 머그컵
  118272015: "https://walla.my/survey/7jYYFLaw7TAAfdIaXbuW", // 맥세이프 그립톡
  113364445: "https://walla.my/survey/M22IKtVDaTQnrWXylT0F", // 일반 아기얼굴 아크릴톡 
  120649987: "https://walla.my/survey/K6ay9CP90Y9ovIjfpMsD", // 이름 지비츠
  122998052: "https://walla.my/survey/mKoh6Ob4yZh7gvvJEmb8", // 한복 흔들등신대 
  122906608: "https://walla.my/survey/T3LLwpR0lmVrMHr3cgI0", // 십이간지 폰케이스+그립톡 
  123406600: "https://walla.my/survey/FQMeV52HbHG0Vcq2Fc36", // 쑥티콘 마우스패드
}